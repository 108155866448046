<template>
<div>
    <div class="col-md-12">
        <div class="row" style="margin-top:20px">
            <div class="col-md-6">
                <h3 class="page-header">
                <i class="fa fa fa-users animated bounceInDown show-info"></i>
                    SMS Report 
                </h3>
            </div>
            <div class="col-md-6 ">
                <div class="row" style="padding-top:18px;justify-content:flex-end;">
                    <div v-if="viewno==2" class="col-md-2" style="margin-right:25px;">             
                        <button @click="back()" class="btn btn-primary" > Back</button>
                    </div>
                    <div class="col-md-2" style="margin-right:25px;">             
                        <button @click="refresh()" class="btn btn-primary" ><i class="fa fa-refresh"></i>  Refresh</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body" style="border: 1px solid rgb(233, 219, 219);" v-if="viewno==1">
        <div class="col-md-12 flex-between-row">
            <div class="row">
                <label for="">Promotional Credits :</label>
                <div v-if="companyuser?.company!=null">{{companyuser.company.promotionalcredits}}</div>
            </div>
            <div class="row"> 
                <label for="">Transactional Credits :</label>
                <div v-if="companyuser?.company!=null">{{companyuser.company.transactionalcredits}}</div>
            </div>
        </div>
        <div class="table-responsive">
            <div class="panel-body" style="overflow-x:auto;padding-left:0px">
                <table class="table users-table table-condensed table-hover table-bordered table-sm">
                    <thead class="table-font" style="font-size:13px;">
                        <tr>
                            <th class="visible-lg">#</th>
                            <th class="visible-lg" >Message Type</th>
                            <th class="visible-lg">Message</th>
                            <th>Date </th>
                            <th>Response</th>
                        </tr>
                    </thead>
                    <tbody class="table-font">
                        <tr v-for="(item,index) in report" :key="item.id">
                            <td>{{index+1}}</td>
                            <td><a href="#" @click="details(item)">{{item.istransactional==1?"Transactional":"Promotional"}}</a></td>
                            <td>{{item.message}}</td>
                            <td>{{moment(item.created_at).format('DD-MM-YYYY')}} at {{moment(item.created_at).format('h:m A')}}</td>
                            <td><span v-if="item.response!=null" style="color:green">Success</span></td>
                        </tr>
                    </tbody>
                </table>
                <div class="col-md-12" style="margin-top: 20px">
                    <nav aria-label="Page navigation example" style="text-align: right !important">   
                        <paginate
                            style="margin: 0px !important; float: right"
                            :page-count="noofpages"
                            :click-handler="clickCallback"
                            :prev-text="'&laquo;'"
                            :next-text="'&raquo;'"
                            :container-class="'pagination'">
                        </paginate>
                    </nav>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <label for="">Note:</label><p> If message count is greater than 160 character ,2 credits will be deducted.</p>
        </div>
    </div>
    <sms-report-details v-if="viewno==2" :smsid="smsid"></sms-report-details>
</div>
</template>
<script>
import moment from 'moment';
import Paginate from "vuejs-paginate";
import smsReportDetails from "./SmsReportDetails.vue";
import { mapGetters } from 'vuex'
export default {
    components:{Paginate,smsReportDetails},
    data() {
        return {
            report:[],
            currentpage:1,
            noofrec:10,
            moment:moment,
            totalsmscount:0,
            viewno:1,
            smsid:[]
        }
    },
    computed:{
        ...mapGetters(['companyuser']),
        noofpages(){
            return Math.ceil(parseInt(this.totalsmscount) / parseInt(this.noofrec))
        }
    },
    mounted() {
        this.refresh();
    },
    methods: {
        
        clickCallback (currentpage) {
            this.currentpage=currentpage
            this.refresh();
        },
        refresh(){  
            let param={noofrec:this.noofrec,currentpage:this.currentpage}
            this.$http.post('api/sms/fetch/report',param)
            .then((response) => this.processSMSResponse(response.data))
            .catch((err) => {
                console.log('', err)
            });
            this.$http.post('api/sms/fetch/report/count')
            .then((response) => this.processSMSResponseCount(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },
        processSMSResponse(data){
            this.report=data
        },
        processSMSResponseCount(data){
            this.totalsmscount=data
        },
        details(item){
            this.smsid=item.id
            this.viewno=2
            
        },
        processSMSResponseDetails(data){
            this.smsdetails=data
        },
        back(){
            this.viewno=1
        }
    },
}
</script>