<template>
<div>
    <div class="card-body" style="border: 1px solid rgb(233, 219, 219);">
        <div class="">
            
        </div>
        <div class="table-responsive">
            <div class="panel-body" style="overflow-x:auto;padding-left:0px">
                <table class="table users-table table-condensed table-hover table-bordered table-sm">
                    <thead class="table-font" style="font-size:13px;">
                        <tr>
                            <th class="visible-lg">#</th>
                            <th class="visible-lg" >Vsdigi Id</th>
                            <th class="visible-lg">Name</th>
                            <th>Mobile No.</th>
                            <!-- <th>Message </th> -->
                            <th>Message Type</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody class="table-font">
                        <tr v-for="(item,index) in smsdetails" :key="item.id">
                            <td>{{index+1}}</td>
                            <td><span v-if="item.users!=null">{{item.users.generatedid}}</span></td>
                            <td><span v-if="item.users!=null">{{item.users.name}}</span></td>
                            <td>{{item.mobile}}</td>
                            <!-- <td>{{item.message}}</td> -->
                            <td>{{item.istransactional==1?"Transactional":"Promotional"}}</td>
                            <td>{{moment(item.created_at).format('DD-MM-YYYY')}} at {{moment(item.created_at).format('h:m A')}}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="col-md-12" style="margin-top: 20px">
                    <nav aria-label="Page navigation example" style="text-align: right !important">   
                        <paginate
                            style="margin: 0px !important; float: right"
                            :page-count="noofpages"
                            :click-handler="clickCallback"
                            :prev-text="'&laquo;'"
                            :next-text="'&raquo;'"
                            :container-class="'pagination'">
                        </paginate>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import moment from 'moment';
import Paginate from "vuejs-paginate";
import { mapGetters } from 'vuex';

export default {
    components:{Paginate},
    props:['smsid'],
    data() {
        return {
            noofrec:10,
            currentpage:1,
            moment:moment,
            smsdetails:[],
            smscount:0
        }
    },
    computed:{
        ...mapGetters([]),
        noofpages(){
            return Math.ceil(parseInt(this.smscount) / parseInt(this.noofrec))
        }
    },
    mounted() {
        this.refresh()
        let param={smsid:this.smsid}
        this.$http.post('api/sms/fetch/details/count',param)
            .then((response) => this.processSMSResponseCount(response.data))
            .catch((err) => {
                console.log('', err)
            });
    },
    methods: {
        clickCallback (currentpage) {
            this.currentpage=currentpage
            this.refresh();
        },
        processSMSResponseCount(data){
            this.smscount=data
        },
        refresh(){
            let param={smsid:this.smsid}
            this.$http.post('api/sms/fetch/details',param)
            .then((response) => this.processSMSResponseDetails(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },
        processSMSResponseDetails(data){
            this.smsdetails=data
        }
    },
}
</script>